
import {defineComponent, ref} from "vue";
import {ErrorMessage, Field, Form} from "vee-validate";
import {useStore} from "vuex";
import * as Yup from "yup";
import {Actions} from "@/store/enums/StoreEnums";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import {useRouter} from "vue-router";
import ApiService from "@/core/services/ApiService";
import {translate} from "@/core/helpers/functions";
import {useI18n} from "vue-i18n";

export default defineComponent({
  name: "password-reset",
  components: {
    Field,
    Form,
    ErrorMessage,
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const visibleCode = ref(false);
    const visibleEmail = ref(true);
    const visiblePassword = ref(false);
    const code = ref("");
    const password = ref("");
    const confirmPassword = ref("");
    const email = ref("");
    const {t} = useI18n();

    const submitButton = ref<HTMLButtonElement | null>(null);
    const checkCode = () => {
      ApiService.post(`/api/password/code/check`, {
        code: code.value,
      }).then(function () {
        visiblePassword.value = true;
        visibleCode.value = false;
      });
    };
    const addCode = () => {
      visibleEmail.value = false;
      visibleCode.value = true;
    };
    //Create form validation object
    const forgotPassword = Yup.object().shape({
      email: Yup.string().email(translate("vemail")).required(translate("remail")),
    });
    const discard = () => {
      router.push({path: "/"});
    };

    //Form submit function
    const onSubmitForgotPassword = (values) => {
      submitButton.value?.setAttribute("data-kt-indicator", "on");
      if (visiblePassword.value) {
        if (password.value === confirmPassword.value) {
          ApiService.post(`/api/password/reset`, {
            code: code.value,
            password: password.value,
            password_confirmation: confirmPassword.value,
          }).then(function () {
            visiblePassword.value = true;
            visibleCode.value = false;
            submitButton.value?.removeAttribute("data-kt-indicator");
            Swal.fire({
              text: t("passwordChanged"),
              icon: "success",
              buttonsStyling: false,
              confirmButtonText: t("thanks"),
              customClass: {
                confirmButton: "btn fw-bold btn-light-primary",
              },
            }).then(function () {
              router.push({path: "/"});
            });
          }).catch(() => {
            submitButton.value?.removeAttribute("data-kt-indicator");
          });
        } else {
          submitButton.value?.removeAttribute("data-kt-indicator");
          Swal.fire({
            text: t("passwordMatch"),
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: t("thanks"),
            customClass: {
              confirmButton: "btn fw-bold btn-light-primary",
            },
          }).then(function () {
            //
          });
          submitButton.value?.removeAttribute("data-kt-indicator");
        }
      } else {
        store.dispatch(Actions.FORGOT_PASSWORD, {email: email.value}).then(() => {
          visibleCode.value = true;
          visibleEmail.value = false;
          submitButton.value?.removeAttribute("data-kt-indicator");
        }).catch(() => {
          submitButton.value?.removeAttribute("data-kt-indicator");
        });
      }
    };

    return {
      onSubmitForgotPassword,
      forgotPassword,
      submitButton,
      visibleCode,
      visibleEmail,
      visiblePassword,
      code,
      password,
      email,
      confirmPassword,
      discard,
      checkCode,
      addCode,
    };
  },
});
